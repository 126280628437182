
import icons from './icons.json'

export default {
  name: 'AppIcon',
  props: {
    name: {
      type: String,
      default: 'users',
    },
  },
  computed: {
    path() {
      return icons[this.name]
    },
  },
}
